import React from "react"
import { Link } from "gatsby"
import returnToTopIcon from "../images/return-to-top.svg"

const ReturnToTop = () => {
    return (
        <div style={{
            paddingBottom: 75,
            textAlign: "center",
            color: "#646464",
            background: "#fff"
        }}>
            <Link to={"#top"}
               style={{
                   textDecoration: "none",
                   color: "#424242",
                   fontSize: 14
               }}>
                <img src={returnToTopIcon} alt={"このページのトップに戻る"} style={{
                    display: "block",
                    margin: "auto"
                }} />
                <span style={{
                    display: "inline-block",
                    marginTop: 13
                }}>
                    Return to the top of this page
                </span>
            </Link>
        </div>
    )
}

export default ReturnToTop
